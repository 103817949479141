/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li",
    code: "code",
    pre: "pre",
    em: "em",
    h3: "h3",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "modular-factorial",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#modular-factorial",
    "aria-label": "modular factorial permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Modular Factorial"), "\n", React.createElement(_components.p, null, "of a number and Program to find modular factorial using Modular Arithmetic in Rust."), "\n", React.createElement(_components.h2, {
    id: "why-do-we-need-modulo",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#why-do-we-need-modulo",
    "aria-label": "why do we need modulo permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Why do we need Modulo"), "\n", React.createElement(_components.p, null, "The program discussed in ", React.createElement(_components.a, {
    href: "/number-theory/factorial-of-number/"
  }, "Finding Factorial Of a Number"), " page finds the factorial of a given number. But factorial of number grows very fast with number. ", React.createElement(_components.strong, null, "Factorial of 100 is 9.33 × 10", React.createElement("sup", null, "157")), "\nSo, it becomes impossible to store such large number as number in many languages, like ", React.createElement(_components.strong, null, "C, C++, Rust"), " etc. ( Though some languages like Python allow number of any length being stored )."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Largest integer data type in rust is ", React.createElement(_components.code, null, "i128"), " which can store only the numbers of 128 bits which is roughly of order ", React.createElement(_components.strong, null, React.createElement("code", null, "10", React.createElement("sup", null, "38"))), " ", React.createElement("br"), " ", React.createElement("br")), "\n", React.createElement(_components.li, null, "If we try to store a number beyond this range, ", React.createElement(_components.strong, null, "the number will overflow, and an error will be thrown!."), " Like, if we try to find 100!"), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-python"
  }, "thread 'main' panicked at 'attempt to multiply with overflow', src/iterative.rs:10:9\n")), "\n", React.createElement(_components.p, null, "Therefore, we must find an alternate to store and use the factorial of larger numbers."), "\n", React.createElement(_components.h2, {
    id: "using-modulo-of-number-to-store",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#using-modulo-of-number-to-store",
    "aria-label": "using modulo of number to store permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Using Modulo of Number to store"), "\n", React.createElement(_components.p, null, "As we saw above, we can not store the complete number. But we can store it's modulo from a number."), "\n", React.createElement(_components.p, null, "In most programming contest, a specific number is mentioned, generally ", React.createElement(_components.strong, null, React.createElement("code", null, "10", React.createElement("sup", null, "9"), " + 7 or 1000000007")), " is used because It is ", React.createElement(_components.em, null, "safe prime number"), "."), "\n", React.createElement(_components.p, null, "But we will see a function that generates factorial of a number modulo any other number. Also, it is guaranteed that number will be less than the second number. That is, if we find factorial modulo 13, it is guaranteed that the answer will be less than 13.\nHence, it will ensure that the number doesn't overflow."), "\n", React.createElement(_components.h3, {
    id: "property-used",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#property-used",
    "aria-label": "property used permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Property Used"), "\n", React.createElement(_components.p, null, "We take the help of special property in Modular Mathematics, which is called Modular Multiplication Property\n", React.createElement("center", null, " ", React.createElement("b", null, "(a x b) mod m = ((a mod m) x (b mod m)) mod m"), " ")), "\n", React.createElement(_components.p, null, "Also, we know that ", React.createElement(_components.strong, null, "n! = n × (n-1)!"), ". Therefore,\n", React.createElement(_components.strong, null, "n! mod m = ((n mod m) x ((n-1)! mod m)) mod m")), "\n", React.createElement(_components.p, null, "Also, ", React.createElement(_components.code, null, "n mod m = n"), " because if n is greater or equal to m, final result will be 0, because n! will already contain m, and hence, it is always divisible by m."), "\n", React.createElement(_components.p, null, "If n is less than m, than n mod m = n always."), "\n", React.createElement(_components.p, null, "Also, (n-1)! is already modulo m. So, no need to find modulo m again.So, finally, we use equation"), "\n", React.createElement("center", null, " ", React.createElement("b", null, "n! mod m = ( n x (n-1)! ) mod m"), " "), "\n", React.createElement(_components.h2, {
    id: "recursive-approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#recursive-approach",
    "aria-label": "recursive approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Recursive Approach"), "\n", React.createElement(_components.p, null, "In the code seen in ", React.createElement(_components.a, {
    href: "/number-theory/factorial-of-number/"
  }, "Finding Factorial of a Number"), " page, we just ", React.createElement(_components.strong, null, "return number modulo divisor")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn factorial_recursive(number : i128, divisor: i128) -> i128{\n\n    // Base Case\n    if number<=1 {\n        return 1;\n    }\n\n    // Recursive Case\n    return (number * factorial_recursive(number-1, divisor) ) % divisor;\n}\n")), "\n", React.createElement(_components.p, null, "Program with Driver Code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io::stdin;\n\nfn factorial_recursive(number : i128, divisor: i128) -> i128{\n\n    // Base Case\n    if number<=1 {\n        return 1;\n    }\n\n    // Recursive Case\n    return (number * factorial_recursive(number-1, divisor) ) % divisor;\n}\n\n// Driver Code\n\npub fn main() {\n\n    // Read and parse number to i128\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    let number : i128 = input.trim().parse().unwrap();\n\n    input.clear();\n\n    stdin().read_line(&mut input).unwrap();\n    let divisor : i128 = input.trim().parse().unwrap();\n\n    // Find and print factorial\n    let factorial = factorial_recursive(number, divisor);\n    println!(\"Factorial of {} is : {}\", number,  factorial);\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "12345 ", React.createElement("br"), "\n1000000007 ", React.createElement("br"), "\nFactorial of 12345 is : 579592771"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O(n)"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O(n)")), "\n", React.createElement(_components.p, null, "As you can see, we can easily find factorial of number as large as ", React.createElement(_components.code, null, "12345"), " modulo some other number easily."), "\n", React.createElement(_components.h2, {
    id: "iterative-approach",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#iterative-approach",
    "aria-label": "iterative approach permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Iterative Approach"), "\n", React.createElement(_components.p, null, "In this, we multiply all the number from 1 to the given number, and each time store the remainder."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn factorial(number : i128, divisor: i128) -> i128{\n\n    // initialize factorial to 1, explicitly type i128\n    let mut factorial : i128 = 1;\n\n    // Multiply factorial by all numbers from 1 to the given number\n    for i in 1..(number+1) {\n        factorial*=i;\n        // Find remainder\n        factorial%=divisor;\n    }\n\n    // Return factorial\n    return factorial\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "12345 ", React.createElement("br"), "\n1000000007 ", React.createElement("br"), "\nFactorial of 12345 is : 579592771"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O(n)"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O(1)")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Note :"), " Iterative approach is more efficient than recursive approach !"), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "This article covered how to find factorial of very large numbers, using both Iterative and recursive methods in rust."), "\n", React.createElement(_components.p, null, "Here is function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn factorial(number : i128, divisor: i128) -> i128{\n    let mut factorial : i128 = 1;\n    for i in 1..(number+1) {\n        factorial*=i;\n        factorial%=divisor;\n    }\n    return factorial\n}\n")), "\n", React.createElement(_components.p, null, "In the next article, we will see how to find the factorial of multiple numbers."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Modular Factorial - Rust Programming",
    description: "Factorial is an important value of a number. But calculating factorial of large numbers can result in overflow. In this article, we calculate modular factorial of large numbers using Modular Arithmetic in Rust."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
